import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Typography, Input, List, Flex, Card, Divider } from 'antd';

import DOMPurify from "dompurify";

const formatDuration = (trackTimeMillis) => {
  const duration = (trackTimeMillis / 1000 / 60).toFixed(0);
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  if (hours === 0) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  } else if (minutes === 0) {
    return `${hours} hour${hours !== 1 ? 's' : ''}`;
  } else {
    return `${hours} hour${hours !== 1 ? 's' : ''} and ${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }
}

const Home = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    navigate(`/result/${searchTerm}`);
  };

  const boxStyle = {
    width: '100%',
    marginTop: 100
  };

  return (
    <Flex style={boxStyle} justify="center" align="center">
      <Card title="Search for pod" style={{ width: 400 }}>
        <Input.Search
          style={{ width: 300, marginTop: 20 }}
          placeholder="Search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onSearch={handleSearch}
        />
      </Card>
    </Flex>
  );
};


const boxStyle = {
  width: '100%'
};

const Result = () => {
  const navigate = useNavigate();
  const { searchTerm } = useParams();
  const [ pods, setPods] = useState([]);
  const [ loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/api/search?name=' + searchTerm);
      const json = await response.json();
      setPods(json.results)
      setLoading(false);
    }
    fetchData();
  }, [searchTerm]);

  useEffect(() => {    
    document.title = "Search result for " + searchTerm;
  }, [searchTerm]);

  const handleClick = (collectionId) => {
    navigate(`/pod/${collectionId}`);
  };

  if (loading) {
    return (<div>Loading...</div>)
  }

  return (
    <Flex style={boxStyle} justify="center" align="flex-start">
      <List
        itemLayout="horizontal"
        bordered
        style={{ width: 500 }}
        dataSource={pods}
        renderItem={(item) => (
          <List.Item onClick={() => handleClick(item.collectionId) } style={{cursor:'pointer'}} >
            <List.Item.Meta
              avatar={<img src={item.artworkUrl100} />}
              title={item.trackName}
              description={item.artistName}
            />
          </List.Item>
        )}
      />
    </Flex>
  );
};

const Pod = () => {
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const [ episodes, setEpisodes ] = useState([]);
  const [ pod, setPod ] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/api/pod?collectionId=' + collectionId);
      const json = await response.json();
      setEpisodes(json.results.filter(r => r.wrapperType == 'podcastEpisode'))
      setPod(json.results.filter(r => r.wrapperType == 'track')[0])
    }
    fetchData();
  }, [collectionId]);

  useEffect(() => {    
    document.title = pod.trackName;
  }, [pod]);

  const handleClick = (collectionId, trackId) => {
    navigate(`/pod/${collectionId}/episode/${encodeURIComponent(trackId)}`);
  };

  if (pod.trackName == null) {
    return (<div>Loading...</div>)
  }

  return (
    <div>
      <Breadcrumb 
        items={[ { title: <a href="/">Search</a> } ]}
        style={{margin: 10}}
      />
      <Card title={pod.trackName}>
        <img src={pod.artworkUrl600} style={{ width: 300, height: 300}} />
        <p>Last release: { new Date(pod.releaseDate).toLocaleString() }</p>
        <p>Number of episodes: {pod.trackCount}</p>
        <p><a href={pod.collectionViewUrl}>Homepage</a></p>
      </Card>
      <List
        itemLayout="horizontal"
        bordered
        dataSource={episodes}
        renderItem={(item) => (
          <List.Item >
            <List.Item 
              onClick={() => handleClick(collectionId, item.trackId) } 
              style={{ width: '100%', padding: 10, cursor:'pointer' }} >

              <List.Item.Meta
                description={
                  <div>
                    <p><img src={item.artworkUrl160} /></p>
                    <p><Typography.Title level={5}>{ item.trackName } </Typography.Title></p>
                    <p>{ new Date(item.releaseDate).toLocaleString() }</p>
                    <p>{ formatDuration(item.trackTimeMillis) }</p>
                    <p>{ item.shortDescription }</p>
                    <p>
                      <audio controls preload="none">
                        <source src={ item.episodeUrl } type="audio/mpeg" />
                      </audio>
                    </p>
                  </div>
                }
              />
            </List.Item>
          </List.Item>
        )}
      />
    </div>
  );
}

const Episode = () => {
  const { collectionId, trackId } = useParams();
  const [ episode, setEpisode ] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/api/pod?collectionId=' + collectionId);
      const json = await response.json();
      const episodes = json.results.filter(r => r.wrapperType == 'podcastEpisode')
      const episode = episodes.filter(e => e.trackId == trackId)[0];
      console.log(episode);
      setEpisode(episode)
    }
    fetchData();
  }, [collectionId]);

  useEffect(() => {    
    document.title = episode.collectionName  + " | " + episode.trackName;
  }, [episode]);

  if (episode.trackName == null) {
    return (<div>Loading...</div>)
  }

  const sanitized = DOMPurify.sanitize(episode.description);
  
  return (
    <div>
      <Breadcrumb items={[
          { title: <a href="/">Search</a> },
          { title: <a href={"/pod/" + collectionId}>{ episode.collectionName }</a>}
        ]}
        style={{margin: 10}}
      />
      <Card style={{ maxWidth: 500 }}>
        <p> <img src={episode.artworkUrl600} style={{width: 300, height: 300}} /> </p>
        <p><Typography.Title level={5}>{ episode.trackName } </Typography.Title></p>
        <p>{ new Date(episode.releaseDate).toLocaleString() } | { formatDuration(episode.trackTimeMillis) }</p>
        <p>
          <audio controls>
            <source src={ episode.episodeUrl } type="audio/mpeg" />
          </audio>
        </p>
        <p><span dangerouslySetInnerHTML={{ __html: sanitized }} /></p>
        <Divider />
        <p><a href={ episode.trackViewUrl }>Apple Podcaster</a></p>
        <p><a href={ episode.episodeUrl}>Download</a></p>
      </Card>
    </div>
  );
}

function App() {
  return (
    <Flex justify="center" align="center">
    <Router>
      <Routes>
        <Route path="/pod/:collectionId" element={<Pod />} />
        <Route path="/pod/:collectionId/episode/:trackId" element={<Episode />} />
        <Route path="/result/:searchTerm" element={<Result />} />
        <Route index path="/" element={<Home />} />
      </Routes>
    </Router>
    </Flex>
  );
}

export default App;
